/*********** our gallery css start *************/
.our_gallery{
    .card-columns{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
        @media #{$small_mobile}{
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
            -webkit-column-gap: 1.25rem;
            -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
            orphans: 1;
            widows: 1;
        }
        .card{
            border-radius: 0;
            border: 1px solid transparent;
            .card-img-top{
                border-radius: 0;
                border: 1px solid transparent;
            }
        }
    }
}